<template>
    <cluji-card class="text-center" title="Nouvel événement"
                subtitle="L'événement devra être validé par le bureau avant d'être visible publiquement">
        <b-form @submit.prevent="submit">
            <b-col lg="8" offset-lg="2">
                <label v-b-tooltip.hover="'Maximum 1Mo, idéalement plutôt format paysage, optionnelle'">
                    Image
                    <font-awesome-icon icon="question-circle"/>
                </label>
                <uploadable-picture :illustrated="null" type="evenements" v-model="uploadPicture"
                                    max-width="800px" class="mb-5 mt-3"/>
            </b-col>
            <b-row>
                <b-col lg="8" offset-lg="2">
                    <two-dates-picker @dateDebutChange="updateDateDebut" @dateFinChange="updateDateFin"/>
                </b-col>
            </b-row>

            <b-col lg="8" offset-lg="2" class="mt-4">
                <b-form-group label="Nom">
                    <b-form-input v-model="form.nom" type="text" required/>
                </b-form-group>

                <b-form-group>
                    <label v-b-tooltip.hover="'Sélectionnez une partie du texte pour afficher les options de mise en forme'"
                           slot="label">
                        Description
                        <font-awesome-icon icon="question-circle"/>
                    </label>
                    <balloon-editor v-model="form.description"/>
                </b-form-group>

                <b-form-group>
                    <label v-b-tooltip.hover="'Petite phrase optionnelle résumant la description (pour affichage dans le fil des événements)'"
                           slot="label">
                        Résumé
                        <font-awesome-icon icon="question-circle"/>
                    </label>
                    <b-form-input v-model="form.resume" type="text"/>
                </b-form-group>
            </b-col>
            <b-btn type="submit" variant="success" class="mt-3 mb-2">
                <font-awesome-icon icon="check"/>
                Valider
            </b-btn>
        </b-form>
    </cluji-card>
</template>

<script>
    import {apiPath}             from '@/util/http';
    import alert                 from '@/util/alert';
    import {checkNonEmptyFields} from '@/util/form';
    import {updateBadgesBureau}  from '@/util/badge';

    const TwoDatesPicker    = () => import('@/components/TwoDatesPicker');
    const UploadablePicture = () => import('@/components/UploadablePicture');
    const BalloonEditor     = () => import('@/components/BalloonEditor');
    const ClujiCard         = () => import('@/components/ClujiCard');
    const DateTimePicker    = () => import('@/components/DateTimePicker');

    export default {
        name: "NouvelEvenement",
        components: {TwoDatesPicker, UploadablePicture, BalloonEditor, ClujiCard, DateTimePicker},
        data: () => ({
            loading: false,
            uploadPicture: null,
            form: {
                nom: null,
                resume: null,
                description: null,
                dateDebut: null,
                dateFin: null
            }
        }),
        methods: {
            updateDateDebut(val) {
                this.form.dateDebut = val;
            },
            updateDateFin(val) {
                this.form.dateFin = val;
            },
            submit() {
                if (!checkNonEmptyFields(this.form, ['nom', 'description', 'dateDebut', 'dateFin'])) {
                    this.$toaster.error("Tous les champs sont obligatoires (à part le résumé et l'image)");
                    return;
                }

                alert.loading();

                let formData = new FormData();
                formData.append('picture', this.uploadPicture);
                formData.append('form', JSON.stringify(this.form));

                this.axios.post(apiPath('nouvel_evenement'), formData)
                    .then(() => {
                        this.$toaster.success("Événement enregistré, il sera visible publiquement quand le bureau l'aura validé");
                        updateBadgesBureau();
                        this.$router.push({name: 'dashboard_list_evenements'});
                    })
                    .catch(() => this.$toaster.error("Impossible de créer l'événement"))
                    .finally(alert.stopLoading);
            }
        }
    }
</script>
